import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Home() {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <Typography paragraph>
                to be done
                </Typography>
            <Typography paragraph>
                to be done
                </Typography>
        </main>
    )
}

export default Home;
