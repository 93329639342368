import React from 'react';
import ReactEcharts from 'echarts-for-react';

function Chart() {
    return (
        // <ReactEcharts/>
        <p>test</p>
    );
}

export default Chart;