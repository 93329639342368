import React from 'react';

// import router utilities
import { Route, Switch } from 'react-router-dom';

// import my components
import Home from '../components/Home';
import BTCPriceNDayChangesChart from '../components/BTCPriceNDayChangesChart';

// navbar utilities
import { makeStyles } from '@material-ui/core/styles';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}));

function Nav() {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.toolbar}>
                <Typography variant="h6" noWrap>Classes</Typography>
            </div>
            <Divider />
            <List>
                <ListItem button>
                    <ListItemIcon><InboxIcon/></ListItemIcon>
                    <ListItemText primary={<NavLink to='/btc-n-day-changes'>BTC 多日涨幅</NavLink>} />
                </ListItem>
                <ListItem button>
                    <ListItemIcon><MailIcon/></ListItemIcon>
                    <ListItemText primary={<NavLink to='/eos-n-day-changes'>EOS 多日涨幅</NavLink>} />
                </ListItem>
            </List>
            <Divider />
            <List>
                {['《韭养》读者增长', '东岳读者增长'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}

// ============================
// Rounter ⬇️

function Main() {
    return (
        <Switch>
            <Route exact path='/' component={Home}></Route>
            <Route
                exact
                path='/btc-n-day-changes'
                component={BTCPriceNDayChangesChart}
            >
            </Route>
            <Route component={Home}></Route>
        </Switch>
    )
}

export {Nav, Main};