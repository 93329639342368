import React from 'react';
import MenuBar from './MenuBar';
import {HashRouter as Rounter} from 'react-router-dom';
import {Nav, Main} from './Router';


class App extends React.Component {

  render() {
    return (
      <Rounter>
        <MenuBar nav={<Nav/>}>
          <Main/>
        </MenuBar>
      </Rounter>
    );
  }
}

export default App;
